// base by fungleo
html {
  background-color: #fff;
}
ul, li, div, img, p, body, dl, dd, dt, h1 {
  padding: 0;
  margin: 0;
}
ul, li {
  list-style: none;
}
a {
  text-decoration: none;
}
body {
  line-height: 1;
  font-family: 'Sen', 'PingFang SC', 'Heiti SC', 'Droidsansfallback', Helvetica, 'Droid Sans';
  font-size: 16px;

  & > iframe {
    display: none !important;z-index: 0 !important;position: static !important;
  }
}
// html,body {overflow: hidden;}
.block {display: block;}
.none {display:none}
.clear {clear: both;}

.fl {float: left;}
.fr {float: right;}
.tl {text-align: left;}
.tr {text-align: right;}
.tc {text-align: center;}

.cf {
  zoom:1;
  &:before,&:after {content:"";display:table;}
  &:after {clear:both;}
}
// 滚动条
::-webkit-scrollbar {width: 4px;height: 10px;}
::-webkit-scrollbar-thumb {background: rgba(#000, .2);border-radius: 5px;}
::-webkit-scrollbar-track-piece{background: #EFF0F6;}
html, body {
  * {
    &::-webkit-scrollbar-thumb {background: rgba(#000, .1);border-radius: 5px;}
    &::-webkit-scrollbar-track-piece{background: transparent;}
  }
}
// 移动端特殊样式
.mobile-mode {
  body {
    font-size: rem(16);
    padding-top: constant(safe-area-inset-top);   //为导航栏+状态栏的高度 88px
    padding-left: constant(safe-area-inset-left);   //如果未竖屏时为0
    padding-right: constant(safe-area-inset-right); //如果未竖屏时为0
    padding-bottom: constant(safe-area-inset-bottom);//为底下圆弧的高度 34px
    font-family: 'Sen';font-weight: normal;
  }
  &::-webkit-scrollbar, body::-webkit-scrollbar, *::-webkit-scrollbar {
    width:0px !important;height:0px !important;display: none !important;background: transparent;
    -webkit-overflow-scrolling: touch;
    &-thumb, &-track-piece {
      background: transparent;
    }
  }
  * {
    user-select:none;
  }

  input, textarea {
    user-select:auto; /*webkit浏览器*/
  }
  input[type="password"] {
    user-select:none;
  }

}
