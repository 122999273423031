.pc-website-desc {
  padding-top: 86px !important;
  padding-bottom: 69px !important;
  & > ul {
    margin-top: 103px; 
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 139px;
    li {
      line-height: 29px;
      font-size: 20px;
      color: $color-666;
    }
  }
  & > img {
    margin-top: 30px;
    width: 100%;
  }
}
@media (max-width: 1366px) {
  .pc-website-desc {
    & > ul {
      li {
        font-size: 16px;
        line-height: 23px;
      }
    }
  }
}