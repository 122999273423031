.pc-website-header {
  min-width: 1200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 98px;
  padding-right: 74px;
  background-color: #fff;
  transition: all .5s;
  z-index: 99;
  box-sizing: border-box;
  &.fixed {
    // background-color: black;
  }
  &-sticky {
    height: 98px;
  }
  &-logo {
    width: 332px;
    height: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-D1;
    & > img {
      width: 305px;
    }
  }
  & > ul {
    display: flex;
    li {
      margin-right: 150px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      a {
        text-decoration: none;
        font-size: 20px;
        font-weight: bold;
        color: $color-50;
      }
    }
  }
}
@media (max-width: 1366px) {
  .pc-website-header {
    top: 0;
    left: 0;
    right: 0;
    height: 110px;
    padding-right: 62px;
    &-sticky {
      height: 98px;
    }
    &-logo {
      width: 320px;
      height: 110px;
      & > img {
        width: 240px;
      }
    }
    & > ul {
      li {
        margin-right: 118px;
        a {
          font-size: 18px;
        }
      }
    }
  }
}