.pc-website-footer {
  height: 60px;
  line-height: 60px;
  text-align: center;
  background-color: $color-111;
  color: $color-fff;
  font-size: 20px;
}
@media (max-width: 1366px) {
  .pc-website-footer {
    height: 52px;
    line-height: 52px;
    font-size: 16px;
  }
}