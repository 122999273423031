.pc-website-banner {
  position: relative;
  padding: 11px 16px 0;
  box-sizing: border-box;
  & > img {
    width: calc(100vw - 32px);
    min-width: 1200px;
  }
  &-desc {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $color-fff;
    transition: all .5s;
    & > h1 {
      font-size: 94px;
      line-height: 132px;
      word-break: keep-all;
    }
    & > p {
      font-size: 43px;
      line-height: 60px;
      margin-top: 17px;
    }
    & > button {
      margin-top: 77px;
      width: 263px;
      height: 89px;
      background-color: $color-D8;
      font-size: 29px;
      border: 0;
      color: $color-fff;
      cursor: pointer;
      transition: all .3s;
      &:hover {
        background-color: $color-A4;
      }
    }
  }
}
@media (max-width: 1366px) {
  .pc-website-banner {
    &-desc {
      & > h1 {
        font-size: 60px;
        line-height: 80px;
      }
      & > p {
        font-size: 30px;
        line-height: 43px;
        margin-top: 13px;
      }
      & > button {
        margin-top: 53px;
        width: 200px;
        height: 67px;
        font-size: 22px;
      }
    }
  }
}