.web-pc-website-intro {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 174px !important;
  padding-bottom: 80px !important;
  &-cont {
    margin-left: 280px;
    & > p {
      font-size: 23px;
      color: $color-666;
      line-height: 36px;
      transition: all .5s;
    }
    & > img {
      width: 585px;
      margin-top: 61px;
      transition: all .5s;
    }
  }
}
@media (max-width: 1366px) {
  .web-pc-website-intro {
    &-cont {
      p {
        font-size: 18px;
        line-height: 28px;
      }
    }
    & > img {
      width: 500px;
      margin-top: 51px;
    }
  }
}