.pc-website-home {
  min-width: 1200px;
  transition: all .5s;
  .pc-website-index {
    padding: 0 137px;
    box-sizing: border-box;
    &:nth-child(2n - 1) {
      background-color: $color-F4;
    }
  }
}