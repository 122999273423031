.pc-website-team {
  width: 100%;
  padding-top: 182px !important;
  padding-bottom: 86px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-wrap {
    width: 100%;
    position: relative;
    margin-top: 104px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 29px;
    .imgs-wrap {
      position: relative;
      &> img {
        width: 100%;
      }
      &-info {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 43px 16px 0;
        box-sizing: border-box;
        background-color: rgba($color-D8, .8);
        color: $color-fff;
        opacity: 0;
        cursor: pointer;
        transition: all .4s;
        & > h1 {
          text-align: center;
          font-size: 29px;
        }
        & > p {
          // font-size: 16px;
          font-size: calc(16 * 100vw / 1920);
          line-height: calc(26 * 100vw / 1920);
          margin-top: 16px;
        }
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .pc-website-team {
    &-wrap {
      .imgs-wrap {
        &-info {
          padding: 25px 12px 0;
          & > h1 {
            font-size: 22px;
          }
          & > p {
            // font-size: 12px;
            // line-height: 16px;
            margin-top: 10px;
          }
        }
      }
    }
  }
}