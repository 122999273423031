.pc-website-teach {
  padding-top: 83px !important;
  padding-bottom: 20px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-wrap {
   margin-top: 84px;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
   grid-column-gap: 43px;
   &-item {
    width: calc((100vw - 274px) / 3);
    margin-bottom: 40px;
    & > img {
      width: 100%;
    }
    & > div {
      color: $color-111;
      font-size: 29px;
      margin-top: 21px;
    }
    & > p {
      color: $color-666;
      font-size: 20px;
      line-height: 29px;
      margin-top: 30px;
    }
   }
  }
}
@media (max-width: 1366px) {
  .pc-website-teach {
    &-wrap {
      &-item {
        & > div {
          font-size: 22px;
        }
        & > p {
          margin-top: 24px;
          font-size: 17px;
        }
      }
    }
  }
}