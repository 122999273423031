.pc-website-title {
  display: inline-block;
  position: relative;
  font-size: 29px;
  color: $color-111;
  word-break: keep-all;
  margin-left: 100px;
  align-self: flex-start;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -20px;
    width: 46px;
    height: 7px;
    border-radius: 4px;
    background-color: $color-A4;
  }
}
@media (max-width: 1366px) {
  .pc-website-title {
    font-size: 24px;
    margin-left: 70px;
    &::after {
      bottom: -16px;
      width: 40px;
    }
  }
}